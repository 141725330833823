import { Container } from '@mui/material';
import React from 'react';

import Helmet from '@component/Helmet';

import Hero from './Hero';
import FeaturesSection from './FeaturesSection';

const Features = () => {
  return (
    <Container maxWidth="md">
      <Helmet
        title="Features"
        description="Discover the robust features of ZinariPay, including easy integration, crypto payouts, top-notch security, and global reach. Our platform is designed to empower your business with reliable and scalable cryptocurrency payment solutions."
        keywords="ZinariPay features, cryptocurrency payments, secure payments, blockchain, crypto payouts, integration, fintech, digital payments"
      />

      <Hero />
      <FeaturesSection />
    </Container>
  );
};

export default Features;
