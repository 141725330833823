import { Box, Typography } from '@mui/material';
import { yellow } from '@mui/material/colors';
import React from 'react';

import { CONSOLE_URL } from '@variable';

// TODO: When you remove this, fix the padding on the main container in the layout
const PromoStrip = () => (
  <Box sx={{ p: '5px', background: yellow[400] }} textAlign="center">
    <Typography variant="subtitle2" color="textPrimary" fontWeight="bold">
      {`🤑🍾`} New users get the first 3 months with NO processing fees.{' '}
      <a href={CONSOLE_URL} target={'_blank'} rel="noreferrer">
        Sign up now!
      </a>{' '}
      {`💸🤑`}
    </Typography>
  </Box>
);

export default PromoStrip;
