import React, { FC } from 'react';
import { Paper, Stack, Typography } from '@mui/material';

const Content: FC = () => (
  <>
    <img
      src="/paylink-logo.webp"
      alt="Zinari PayLink"
      style={{
        width: '90%',
        maxWidth: '200px',
        height: 'auto',
        marginBottom: 10,
      }}
    />
    <Paper
      sx={{ p: 2, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
      elevation={2}
    >
      <Stack spacing={4}>
        <img
          src="/paylink-hero.webp"
          alt="Zinari PayLink Hero"
          style={{
            width: '90%',
            maxWidth: '1000px',
            height: 'auto',
          }}
        />

        <Typography variant="body1" component="p" gutterBottom>
          At ZinariPay, we understand that not every business can afford a
          website or an engineering team. That&apos;s why we&apos;ve built{' '}
          <b>Zinari PayLink</b>—a simple way for businesses to collect payments
          in cryptocurrency without the need for technical expertise or coding
          knowledge.
        </Typography>

        <Typography variant="body1" component="p" gutterBottom>
          With PayLink, businesses can sign up, complete a quick KYC (Know Your
          Customer) process, and start sending out payment links to their
          customers—no code integrations or websites required. It’s that simple!
        </Typography>

        <Typography variant="body1" component="p" gutterBottom>
          Once your account is set up, you can generate a payment link in just a
          few clicks. The link will be sent directly to your customer’s email,
          allowing them to pay you with <b>cryptocurrency</b> securely and
          quickly.
        </Typography>

        <Typography variant="body1" component="p" gutterBottom>
          Zinari PayLink removes the complexities from accepting cryptocurrency
          payments, so you can focus on growing your business. No engineering
          skills, no website, just a simple, reliable way to get paid.
        </Typography>

        <Typography variant="body1" component="p" gutterBottom>
          View a live demo on how to create a Zinari PayLink:{' '}
          <a
            href="https://www.youtube.com/watch?v=lDqkzNohwS0"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
        </Typography>
      </Stack>
    </Paper>
  </>
);

export default Content;
