import { Avatar, Box, IconButton, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import React from 'react';

const TeamMember = ({
  image,
  name,
  role,
  linkedin,
}: {
  image: string;
  name: string;
  role: string;
  linkedin: string;
}) => {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Avatar src={image} sx={{ width: 100, height: 100, margin: 'auto' }} />
      <Typography variant="h6" component="h3" gutterBottom>
        {name}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {role}
      </Typography>
      <IconButton href={linkedin} target="_blank">
        <LinkedInIcon sx={{ color: '#0A66C2' }} />
      </IconButton>
    </Box>
  );
};

export default TeamMember;
