import { Box, Typography } from '@mui/material';
import React from 'react';

const WhyChooseUsSection = () => (
  <Box sx={{ textAlign: 'center' }}>
    <Typography variant="h4" component="h2" gutterBottom>
      Why Choose Us?
    </Typography>
    <Typography variant="body1" gutterBottom>
      Our platform offers unmatched security, ease of integration, and a
      commitment to customer satisfaction. We stand out in the industry by
      delivering solutions that empower businesses to grow and succeed in the
      digital economy.
    </Typography>
  </Box>
);

export default WhyChooseUsSection;
