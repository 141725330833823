import React from 'react';
import {
  Box,
  Button,
  Container,
  Grid2 as Grid,
  IconButton,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import { Link as RouterLink } from 'react-router-dom';

import { CONSOLE_URL } from '@variable';

import backgroundImage from './background.webp';

const Footer: React.FC = () => {
  return (
    <Paper
      component="footer"
      sx={{
        bgcolor: 'primary',
        py: 6,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: { xs: 'cover' },
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          {/* Company Logo and Contact Information */}
          <Grid size={'auto'}>
            <Box>
              <img
                src="/logoPurple.webp"
                alt="Company Logo"
                style={{ height: '50px' }}
              />
              {/*<Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>*/}
              {/*  Phone: +1 234 567 890*/}
              {/*</Typography>*/}
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Email:{' '}
                <Link href="mailto:support@zinari.io">support@zinari.io</Link>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Address: Victoria Island, Lagos, Nigeria
              </Typography>
            </Box>
          </Grid>

          {/* Navigation Links */}
          <Grid size={'auto'}>
            <Box>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                Company
              </Typography>
              <Link
                component={RouterLink}
                to="/about"
                variant="body2"
                color="textSecondary"
                display="block"
                gutterBottom
              >
                About Us
              </Link>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                Support
              </Typography>
              <Link
                component={RouterLink}
                to="/contact"
                variant="body2"
                color="textSecondary"
                display="block"
                gutterBottom
              >
                Contact Us
              </Link>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                Legal
              </Typography>
              <Link
                component={RouterLink}
                to="/privacy"
                variant="body2"
                color="textSecondary"
                display="block"
                gutterBottom
              >
                Privacy Policy
              </Link>
              <Link
                component={RouterLink}
                to="/anti-bribery-anti-corruption-and-anti-money-laundering-policy"
                variant="body2"
                color="textSecondary"
                display="block"
                gutterBottom
              >
                Anti-Bribery Policy
              </Link>
              <Link
                component={RouterLink}
                to="/anti-bribery-anti-corruption-and-anti-money-laundering-policy"
                variant="body2"
                color="textSecondary"
                display="block"
                gutterBottom
              >
                Anti-Corruption Policy
              </Link>
              <Link
                component={RouterLink}
                to="/anti-bribery-anti-corruption-and-anti-money-laundering-policy"
                variant="body2"
                color="textSecondary"
                display="block"
                gutterBottom
              >
                Anti-Money Laundering Policy
              </Link>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                Resources
              </Typography>
              <Link
                component={RouterLink}
                to="/pricing"
                variant="body2"
                color="textSecondary"
                display="block"
                gutterBottom
              >
                Pricing & Fees
              </Link>
              <Link
                component={RouterLink}
                to="/supported-fiat-currencies"
                variant="body2"
                color="textSecondary"
                display="block"
                gutterBottom
              >
                Supported Fiat Currencies
              </Link>
              <Link
                component={RouterLink}
                to="https://www.npmjs.com/package/zinari-pay"
                target="_blank"
                variant="body2"
                color="textSecondary"
                display="block"
                gutterBottom
              >
                Documentation & Integration guides
              </Link>
            </Box>
          </Grid>

          {/* Social Media Links */}
          <Grid size={'auto'}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Follow Us
            </Typography>
            <Box>
              <IconButton
                href="https://web.facebook.com/zinaripay"
                target="_blank"
              >
                <FacebookIcon sx={{ color: '#1877F2' }} />
              </IconButton>
              <IconButton
                href="https://instagram.com/zinaripay"
                target="_blank"
              >
                <InstagramIcon sx={{ color: '#E4405F' }} />
              </IconButton>
              <IconButton
                href="https://www.linkedin.com/company/zinaripay/"
                target="_blank"
              >
                <LinkedInIcon sx={{ color: '#0A66C2' }} />
              </IconButton>
              <IconButton href="https://x.com/zinaripay" target="_blank">
                <XIcon sx={{ color: '#000' }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        {/* Call to Action */}
        <Box textAlign="center" sx={{ mt: 4 }}>
          <Link
            component={RouterLink}
            to={CONSOLE_URL}
            target="_blank"
            variant="button"
            color="primary"
            sx={{ fontWeight: 'bold' }}
          >
            Join Us Today
          </Link>
        </Box>

        {/* Back to Top Button */}
        <Box textAlign="center" sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              window.scroll({ behavior: 'smooth', top: 0, left: 0 });
            }}
          >
            Back to Top
          </Button>
        </Box>

        {/* Copyright Information */}
        <Box textAlign="center" sx={{ mt: 4 }}>
          <Typography variant="body2" color="textSecondary">
            © 2024 ZinariPay. All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
};

export default Footer;
