import { Box, Button, Typography } from '@mui/material';
import React from 'react';

import { CONSOLE_URL } from '@variable';

const CallToActionSection = () => (
  <Box
    sx={{
      py: 10,
      backgroundColor: '#6a3fdc',
      color: 'white',
      textAlign: 'center',
    }}
  >
    <Typography variant="h4" component="h2" gutterBottom>
      Join Us on This Journey
    </Typography>
    <Typography variant="body1" gutterBottom>
      Ready to take the next step? Contact us to learn more or sign up for early
      access to our platform.
    </Typography>
    <Button
      variant="contained"
      color="secondary"
      sx={{ mt: 2 }}
      href={CONSOLE_URL}
      target={'_blank'}
    >
      Get Started
    </Button>
  </Box>
);

export default CallToActionSection;
