import React from 'react';
import {
  Box,
  Button,
  Container,
  Grid2 as Grid,
  Typography,
} from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import ScaleIcon from '@mui/icons-material/TrendingUp';
import SupportIcon from '@mui/icons-material/SupportAgent';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Link as RouterLink } from 'react-router-dom';

const REASONS = [
  {
    icon: <SecurityIcon fontSize="large" />,
    title: 'Top-Notch Security',
    description:
      'We prioritize your safety with advanced encryption and compliance with global security standards.',
  },
  {
    icon: <ScaleIcon fontSize="large" />,
    title: 'Scalable Solutions',
    description:
      'Our gateway grows with your business, handling high transaction volumes without a hitch.',
  },
  {
    icon: <SupportIcon fontSize="large" />,
    title: '24/7 Customer Support',
    description:
      'We’re here for you around the clock, offering expert support whenever you need it.',
  },
  {
    icon: <VerifiedUserIcon fontSize="large" />,
    title: 'Trusted by Businesses',
    description:
      'Join the growing number of businesses that rely on us for secure and efficient payments.',
  },
];

const WhyChooseUsSection = () => {
  return (
    <Box sx={{ py: 10 }}>
      <Container maxWidth="md">
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ textAlign: 'center' }}
        >
          Why Choose Us?
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ textAlign: 'center', mb: 4 }}
        >
          Discover the benefits of choosing our gateway, designed to empower
          your business with secure, scalable, and reliable payment solutions.
        </Typography>
        <Grid container spacing={4}>
          {REASONS.map((reason, index) => (
            <Grid size={{ xs: 12, sm: 6 }} key={index}>
              <Box sx={{ textAlign: 'center' }}>
                {reason.icon}
                <Typography variant="h6" component="h3" gutterBottom>
                  {reason.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {reason.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/contact"
          >
            Contact Us
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default WhyChooseUsSection;
