import CodeIcon from '@mui/icons-material/Code';
import BuildIcon from '@mui/icons-material/Build';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SecurityIcon from '@mui/icons-material/Security';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import InsightsIcon from '@mui/icons-material/Insights';
import PublicIcon from '@mui/icons-material/Public';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TuneIcon from '@mui/icons-material/Tune';
import CodeOffIcon from '@mui/icons-material/CodeOff';

export const FEATURES = [
  {
    title: 'Easy Integration',
    icon: CodeIcon,
    description:
      'Integrate our gateway seamlessly using our CDN and NPM packages, designed to be versatile across various platforms and development environments.',
    details:
      'Our CDN and NPM packages provide a flexible integration solution, supported by <a href="https://www.npmjs.com/package/zinari-pay" target="_blank">comprehensive documentation</a> that offers step-by-step guides to help you get started quickly, regardless of your platform or setup.',
  },
  {
    title: 'No Coding Required',
    icon: CodeOffIcon,
    description:
      'Zinari PayLink allows businesses to accept crypto payments without coding or technical skills.',
    details:
      'Zinari PayLink is designed for businesses that do not have access to websites or engineering teams. By signing up and completing the KYC process, users can generate payment links to share with customers via email. Customers can then use these links to pay for products and services with cryptocurrency. No technical expertise or code integration is required—just a simple and secure way to collect payments.',
  },
  {
    title: 'Developer-Friendly Environment',
    icon: BuildIcon,
    description:
      'Build and integrate with ease using our developer-centric tools and resources.',
    details:
      'Our payment gateway is designed with developers in mind, offering comprehensive API documentation, SDKs, and sandbox environments for testing. Whether you’re integrating our gateway into a new or existing application, our tools and resources make the process straightforward and efficient. Join our active developer community to share knowledge and get support as you build your solutions.',
  },
  {
    title: 'Crypto Payouts',
    icon: AttachMoneyIcon,
    description:
      'Enjoy the benefits of fast, secure, and global transactions with our cryptocurrency payout options. Receive payments directly in popular cryptocurrencies.',
    details:
      'Our platform supports multiple cryptocurrencies, including USDT & USDC, allowing you to receive payments quickly and securely without the need for traditional banking systems. With near-instant transactions and low fees, crypto payouts offer a modern solution for global commerce.',
  },
  {
    title: 'Security',
    icon: SecurityIcon,
    description:
      'Protect your transactions with industry-leading security measures that ensure the safety and integrity of your payments.',
    details:
      'Our gateway uses advanced encryption protocols to secure all transactions, safeguarding sensitive information from unauthorized access. We comply with global security standards, including PCI DSS, and perform regular security audits to maintain the highest level of protection for your business and your customers.',
  },
  {
    title: 'KYC & Compliance',
    icon: VerifiedUserIcon,
    description:
      'Ensure your business meets regulatory standards with our robust KYC and compliance features.',
    details:
      'Our platform automates the Know Your Customer (KYC) process, making it easy to verify the identity of your users while staying compliant with international regulations. We integrate with leading third-party services to streamline document verification and Anti-Money Laundering (AML) checks, ensuring that your business operates within legal frameworks and minimizes risk.',
  },
  {
    title: 'Multi-Currency Support',
    icon: CurrencyExchangeIcon,
    description:
      'Expand your reach by accepting payments in multiple cryptocurrencies and fiat currencies, all within a single platform.',
    details:
      'Our gateway supports a wide range of cryptocurrencies, including USDT (Tether) and USDC (Coin), as well as popular fiat currencies such as USD, NGN, ZAR, KES, EGP and many more. This allows you to cater to a global audience, offering localized pricing and seamless currency conversion, all while maintaining transparent and consistent transaction processes.',
  },
  {
    title: 'Detailed Analytics and Reporting',
    icon: InsightsIcon,
    description:
      'Gain valuable insights into your transactions with our comprehensive analytics and reporting tools.',
    details:
      'Our platform provides real-time monitoring and detailed reporting on all your transactions. Access key metrics such as transaction volumes, success rates, and revenue trends through a user-friendly dashboard. Each transaction comes with a blockchain transaction ID, allowing you to track all your incoming and outgoing payments directly on the blockchain. Exportable reports enable you to analyze your data further, helping you make informed business decisions and optimize your payment processes.',
  },
  {
    title: 'Global Reach',
    icon: PublicIcon,
    description:
      'Operate seamlessly across borders with our payment gateway, designed to support businesses worldwide.',
    details:
      'Our platform allows you to accept payments from over 100 countries, with support for multiple currencies. We ensure compliance with regional regulations, making it easier for your business to expand globally. Whether you’re targeting a specific market or operating on a global scale, our gateway provides the tools you need to reach customers anywhere in the world.',
  },
  {
    title: 'Scalability',
    icon: TrendingUpIcon,
    description:
      'Scale your business with confidence, knowing that our payment gateway can handle increasing transaction volumes without compromising performance.',
    details:
      'Our cloud-based infrastructure is built to support businesses of all sizes, from startups to large enterprises. As your business grows, our gateway scales with you, ensuring that you can process high volumes of transactions quickly and reliably. With our robust and scalable architecture, you can focus on growth without worrying about downtime or bottlenecks.',
  },
  {
    title: '24/7 Support',
    icon: SupportAgentIcon,
    description:
      'Access dedicated support whenever you need it, with our around-the-clock customer service.',
    details:
      'We offer 24/7 customer support through multiple channels, including email, chat, and phone, to ensure that help is always available when you need it. Whether you encounter an issue or just have a question, our knowledgeable support team is ready to assist you. For larger clients, we also provide dedicated account managers who can offer personalized guidance and support.',
  },
  {
    title: 'Customization',
    icon: TuneIcon,
    description:
      'Tailor our payment gateway to fit your brand and business needs with flexible customization options.',
    details:
      'Our platform allows you to customize the user interface, payment options, and integration settings to match your brand’s identity. Whether you need specific payment flows, custom webhook notifications, or branded checkout pages, our solution adapts to your unique requirements, ensuring a seamless experience for your users.',
  },
];
