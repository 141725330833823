import { Box, Typography } from '@mui/material';
import React from 'react';

const Hero = () => {
  return (
    <Box sx={{ textAlign: 'center', my: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Explore Our Key Features
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Discover how our cryptocurrency payment gateway can empower your
        business with secure, scalable, and easy-to-integrate solutions.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Our platform offers a wide range of features designed to meet the needs
        of businesses of all sizes. Whether you’re looking for seamless
        integration, advanced security, or global reach, our payment gateway
        provides the tools and resources to help your business thrive.
      </Typography>
    </Box>
  );
};

export default Hero;
