import React from 'react';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { CONSOLE_URL } from '@variable';

const CTABanner = () => {
  return (
    <Box
      sx={{
        py: 10,
        backgroundColor: '#6a3fdc',
        color: 'white',
        textAlign: 'center',
      }}
    >
      <Container maxWidth="md">
        <Typography variant="h4" component="h2" gutterBottom>
          Ready to Revolutionize Your Payments?
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ mb: 4 }}>
          Get started today and experience the future of secure, scalable
          cryptocurrency transactions.
        </Typography>

        <Stack
          direction="row"
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
          }}
          gap={2}
          justifyContent={'center'}
        >
          <Button
            variant="contained"
            color="secondary"
            size="large"
            href={CONSOLE_URL}
            target={'_blank'}
          >
            Get Started
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            component={RouterLink}
            to="/contact"
          >
            Contact Us
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};

export default CTABanner;
