import React from 'react';
import { Container, Paper } from '@mui/material';

import Helmet from '@component/Helmet';
import Content from '@page/AntiPolicy/Content';

const AntiPolicy = () => (
  <Container maxWidth="md">
    <Helmet
      title="Anti-Bribery, Anti-Corruption, and Anti-Money Laundering Policy"
      description="Read Zinari Corp's commitment to upholding ethical practices with our Anti-Bribery, Anti-Corruption, and Anti-Money Laundering policy. Learn how we safeguard integrity in our operations."
      keywords="Anti-Bribery policy, Anti-Corruption policy, Anti-Money Laundering policy, ethical practices, compliance, Zinari Corp"
    />
    <Paper
      sx={{ p: 2, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
      elevation={2}
    >
      <Content />
    </Paper>
  </Container>
);

export default AntiPolicy;
