import { Box, Grid2 as Grid, Typography } from '@mui/material';
import React from 'react';

import { TEAM_MEMBERS } from '@variable';
import TeamMember from '@component/TeamMember';

const TeamSection = () => (
  <Box sx={{ textAlign: 'center' }}>
    <Typography variant="h4" component="h2" gutterBottom>
      Meet the Team
    </Typography>
    <Grid container spacing={4} justifyContent="center">
      {TEAM_MEMBERS.map((member, index) => (
        <Grid size={{ xs: 12, sm: 6 }} key={index}>
          <TeamMember {...member} />
        </Grid>
      ))}
    </Grid>
  </Box>
);

export default TeamSection;
