import React from 'react';
import {
  Box,
  Button,
  Container,
  Grid2 as Grid,
  Typography,
} from '@mui/material';
import IntegrationIcon from '@mui/icons-material/IntegrationInstructions';
import SecurityIcon from '@mui/icons-material/Security';
import PaymentIcon from '@mui/icons-material/AccountBalanceWallet';
import GlobeIcon from '@mui/icons-material/Public';
import { Link as RouterLink } from 'react-router-dom';

const FEATURES = [
  {
    icon: <IntegrationIcon fontSize="large" />,
    title: 'Easy Integration',
    description:
      'Seamlessly integrate with your existing platforms using our flexible CDN and NPM packages.',
  },
  {
    icon: <PaymentIcon fontSize="large" />,
    title: 'Crypto Payouts',
    description:
      'Receive payments in major cryptocurrencies like USDT and USDC with minimal fees and instant transactions.',
  },
  {
    icon: <SecurityIcon fontSize="large" />,
    title: 'Security',
    description:
      'Protect your transactions with advanced encryption and compliance with global security standards.',
  },
  {
    icon: <GlobeIcon fontSize="large" />,
    title: 'Global Reach',
    description:
      'Operate seamlessly across borders, with support for multiple currencies and compliance with regional regulations.',
  },
];

const KeyFeaturesSection = () => {
  return (
    <Box sx={{ py: 10 }}>
      <Container maxWidth="md">
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ textAlign: 'center' }}
        >
          Key Features
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ textAlign: 'center', mb: 4 }}
        >
          Discover the powerful features that make our cryptocurrency payment
          gateway the perfect solution for your business.
        </Typography>
        <Grid container spacing={4}>
          {FEATURES.map((feature, index) => (
            <Grid size={{ xs: 12, sm: 6 }} key={index}>
              <Box sx={{ textAlign: 'center' }}>
                {feature.icon}
                <Typography variant="h6" component="h3" gutterBottom>
                  {feature.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {feature.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button
            variant="contained"
            color="primary"
            to="/features"
            component={RouterLink}
          >
            Learn More
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default KeyFeaturesSection;
