import { Box, Typography } from '@mui/material';
import React from 'react';

const ImpactSection = () => (
  <Box sx={{ textAlign: 'center', pb: 10 }}>
    <Typography variant="h4" component="h2" gutterBottom>
      Our Impact and Ambitions
    </Typography>
    <Typography variant="body1" gutterBottom>
      As we prepare to launch, our ambition is to make a significant impact in
      the cryptocurrency payment industry. We are committed to continuous
      innovation and contributing to the growth and success of businesses around
      the world.
    </Typography>
  </Box>
);

export default ImpactSection;
