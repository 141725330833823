import { Box, Stack, Typography } from '@mui/material';

const OurStory = () => {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Our Story
      </Typography>
      <Stack spacing={2}>
        <Typography variant="body1" gutterBottom>
          Founded with the goal of simplifying cryptocurrency transactions for
          businesses, our journey began with a small team of passionate
          individuals who saw the potential of blockchain technology to
          transform the financial landscape.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We started by identifying the key pain points faced by businesses in
          managing and processing cryptocurrency payments. Through relentless
          innovation and a commitment to security, we developed a platform that
          addresses these challenges while providing a seamless and scalable
          solution.
        </Typography>
        <Typography variant="body1" gutterBottom>
          From our initial idea to our upcoming launch, we have focused on
          building a product that empowers businesses to operate with confidence
          in the world of digital currencies. Our journey is just beginning, and
          we are excited to continue growing and evolving alongside our clients.
        </Typography>
      </Stack>
    </Box>
  );
};

export default OurStory;
