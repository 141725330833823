import React from 'react';
import {
  Box,
  Button,
  Container,
  Grid2 as Grid,
  Typography,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CodeIcon from '@mui/icons-material/Code';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PaymentIcon from '@mui/icons-material/AccountBalanceWallet';

import { CONSOLE_URL } from '@variable';

const STEPS = [
  {
    icon: <AccountCircleIcon fontSize="large" />,
    title: 'Create an Account',
    description:
      'Sign up in minutes and start setting up your payment gateway.',
  },
  {
    icon: <CodeIcon fontSize="large" />,
    title: 'Integrate with Your Platform',
    description:
      'Easily integrate using our CDN or NPM packages, with full developer support.',
  },
  {
    icon: <VerifiedUserIcon fontSize="large" />,
    title: 'Complete KYC',
    description:
      'Verify your business identity with our streamlined KYC process.',
  },
  {
    icon: <PaymentIcon fontSize="large" />,
    title: 'Start Receiving Payments',
    description:
      'Begin accepting cryptocurrency payments and enjoy fast, secure transactions.',
  },
];
const HowItWorksSection = () => {
  return (
    <Box sx={{ py: 10 }}>
      <Container maxWidth="md">
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ textAlign: 'center' }}
        >
          How It Works
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ textAlign: 'center', mb: 4 }}
        >
          Getting started with our cryptocurrency payment gateway is quick and
          easy. Follow these simple steps to start accepting payments.
        </Typography>
        <Grid container spacing={4}>
          {STEPS.map((step, index) => (
            <Grid size={{ xs: 12, sm: 6 }} key={index}>
              <Box sx={{ textAlign: 'center' }}>
                {step.icon}
                <Typography variant="h6" component="h3" gutterBottom>
                  {step.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {step.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button
            variant="contained"
            color="primary"
            href={CONSOLE_URL}
            target={'_blank'}
          >
            Get Started
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default HowItWorksSection;
