import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';

const Content: FC = () => (
  <>
    <Typography variant="h1" component="h1" gutterBottom>
      Privacy Policy
    </Typography>
    <Stack spacing={3}>
      <Typography variant="body1">
        Zinari Technologies Nigeria Limited (“Zinari Corp” the “Company”) is a
        limited liability company incorporated under Part A of the Companies and
        Allied Matters Act Cap C20 the Laws of the Federal Republic of Nigeria
        with RC No. 1822434 and having its corporate office at No. 3-9 Olu
        Koleosho Street Off Medical road Ikeja Lagos Nigeria. Zinari Corp has
        the commitment to safeguarding the privacy of its stakeholders including
        its employees customers partners and visitors of our websites and mobile
        platforms and users of our products and services. The Company is the
        owner and holder of the registered Trademark “Zinari Corp” which is the
        trade name of the Company.
      </Typography>
      <Typography variant="body1">
        In this policy when we mention “we” “us” or “our” it refers to Zinari
        Corp which is the controller of your information and data. This privacy
        policy explains how we collect store use manage share and delete or
        otherwise handle your information when you use any of our products
        services and our websites including our Zinari Corp web and mobile
        platforms.
      </Typography>
      <Typography variant="body1">
        As you use any of our products services and websites you do give consent
        that we collect transfer process store disclose and use of your
        information in Nigeria and any other country where we operate. This
        includes any information you choose to provide that is deemed sensitive
        under applicable laws.
      </Typography>
      <Typography variant="body1">
        This privacy policy is applicable to all users of our websites apps
        products services features and others either as an individual or as a
        group.
      </Typography>

      <Typography variant="h2">What information do we collect?</Typography>
      <Typography variant="body1">
        In connection with the products and services we provide or offer to you
        we collect personal and financial information from you while you use our
        products services and websites. We collect these information mostly when
        you register (get on-boarded) on our various products and services – and
        while using our services products and websites.
      </Typography>
      <Typography variant="body1">
        These personal and financial information are necessary when on-boarding
        or accessing our products and services on our websites in order to:
        enable registered users to log in to the site; verify users’ identities;
        establish customers&apos; ability to effect transactions by verifying
        that they are at least 18 years of age; customize and secure user
        experience; in the case of banking details to effect payment of
        customers through our designated payments service; guard against
        potential fraud and money laundering.
      </Typography>
      <Typography variant="body1">
        These personal and financial information include:
      </Typography>
      <ul style={{ listStyle: 'none' }}>
        <li>Your first middle and last names;</li>
        <li>
          Your contact details (such as mobile/telephone numbers e-mail
          addresses);
        </li>
        <li>Your date of birth;</li>
        <li>Your gender;</li>
        <li>Next of Kin details;</li>
        <li>Your home address and telephone number;</li>
        <li>
          Your Bank Account information (including but not limited to Account
          name and number Credit history etc.);
        </li>
      </ul>

      <Typography variant="h2">Security Measures</Typography>
      <Typography variant="body1">
        We prioritize the security of your personal data and implement robust
        technical and organizational measures to protect it from unauthorized
        access, loss, misuse, or alteration. These security measures include:
      </Typography>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <strong>Encryption:</strong> All sensitive personal information is
          encrypted both in transit and at rest using industry-standard
          encryption protocols, such as AES-256 for data at rest and TLS for
          data in transit.
        </li>
        <li>
          <strong>Access Control:</strong> Access to your personal data is
          strictly limited to authorized personnel who need it to perform their
          job responsibilities. We implement role-based access controls (RBAC)
          to restrict access according to the principle of least privilege.
        </li>
        <li>
          <strong>Data Minimization:</strong> We only collect and store the
          minimum amount of personal data necessary for the purposes described
          in this policy, and we periodically review and securely delete
          unnecessary data.
        </li>
        <li>
          <strong>Vulnerability Management:</strong> We regularly update our
          systems and infrastructure to ensure they are protected against known
          vulnerabilities, applying patches and updates as necessary to maintain
          security.
        </li>
        <li>
          <strong>Third-Party Security:</strong> When we engage third-party
          service providers (such as hosting providers, payment processors,
          etc.), we ensure that they also implement strong security measures and
          comply with relevant data protection regulations.
        </li>
        <li>
          <strong>Data Breach Response:</strong> In the event of a data breach,
          we have a dedicated incident response team that will promptly
          investigate and take necessary measures to mitigate any impact.
        </li>
      </ul>

      <Typography variant="h2">Data Retention</Typography>
      <Typography variant="body1">
        We retain your personal data only as long as necessary to fulfill the
        purposes for which it was collected, in accordance with legal and
        regulatory obligations.
      </Typography>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <strong>Transactional Data:</strong> Data related to transactions is
          retained for a minimum of six years to comply with financial
          regulations and anti-money laundering (AML) laws.
        </li>
        <li>
          <strong>Inactive Accounts:</strong> If your account remains inactive
          for a prolonged period (e.g., 24 months), we may anonymize or delete
          your personal data, unless we are required by law to retain it.
        </li>
      </ul>

      <Typography variant="h2">Cookies and Tracking Technologies</Typography>
      <Typography variant="body1">
        We use cookies and similar tracking technologies to enhance your
        experience on our platform, analyze usage patterns, and deliver
        personalized content.
      </Typography>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <strong>Essential Cookies:</strong> These are necessary for the
          operation of our website and services.
        </li>
        <li>
          <strong>Analytical Cookies:</strong> These help us understand how
          visitors interact with our site by collecting anonymized information,
          enabling us to improve our platform&apos;s performance and usability.
        </li>
      </ul>
    </Stack>
  </>
);

export default Content;
