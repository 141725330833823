import { Container, Divider, Paper, Stack } from '@mui/material';
import React from 'react';

import Helmet from '@component/Helmet';

import Overview from './Overview';
import OurStory from './OurStory';
import TeamSection from './TeamSection';
import WhyChooseUsSection from './WhyChooseUsSection';
import ImpactSection from './ImpactSection';
import CallToActionSection from './CallToActionSection';

const COMPONENTS = [
  Overview,
  Divider,
  OurStory,
  Divider,
  TeamSection,
  Divider,
  WhyChooseUsSection,
  Divider,
  ImpactSection,
];

const AboutUs = () => {
  return (
    <Container maxWidth="md">
      <Helmet
        title="About Us"
        description="Learn about ZinariPay, our mission, vision, and the passionate team behind our secure and scalable cryptocurrency payment platform. Discover how we are revolutionizing digital payments for businesses worldwide."
        keywords="ZinariPay about us, cryptocurrency payment company, fintech mission, blockchain payment solutions, digital payments"
      />
      <Paper
        sx={{ p: 2, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
        elevation={2}
      >
        <Stack spacing={10}>
          {COMPONENTS.map((Component, index) => (
            <Component key={index} />
          ))}
        </Stack>
      </Paper>
      <CallToActionSection />
    </Container>
  );
};

export default AboutUs;
