import React from 'react';
import { Container, Paper } from '@mui/material';

import Helmet from '@component/Helmet';
import Content from '@page/PrivacyPolicy/Content';

const PrivacyPolicy = () => (
  <Container maxWidth="md">
    <Helmet
      title="Privacy Policy"
      description="Zinari Corp's Privacy Policy outlines how we collect, use, and protect your personal data. Learn more about our commitment to data security and compliance with privacy laws."
      keywords="Privacy Policy, data protection, personal data, Zinari Corp, data security, compliance, information privacy"
    />
    <Paper
      sx={{ p: 2, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
      elevation={2}
    >
      <Content />
    </Paper>
  </Container>
);

export default PrivacyPolicy;
