import { Box, Typography } from '@mui/material';
import React from 'react';

const Overview = () => {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Our Mission
      </Typography>
      <Typography variant="body1" gutterBottom>
        To revolutionize the way businesses manage cryptocurrency transactions
        with security, ease of integration, and global reach at the forefront of
        our solutions.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
        Our Vision
      </Typography>
      <Typography variant="body1" gutterBottom>
        We aim to become the leading cryptocurrency payment gateway, empowering
        businesses of all sizes to operate globally with the utmost confidence
        in the security and efficiency of their transactions.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
        Our Values
      </Typography>
      <Typography variant="body1" gutterBottom>
        Innovation, transparency, and customer-centricity are at the core of
        everything we do. We are committed to continuously improving our
        platform to meet the evolving needs of our clients.
      </Typography>
    </Box>
  );
};

export default Overview;
