const {
  REACT_APP_TAWKTO_PROPERTY_ID,
  REACT_APP_TAWKTO_WIDGET_ID,
  REACT_APP_CONSOLE_URL,
} = process.env;

export const TAWKTO_PROPERTY_ID = REACT_APP_TAWKTO_PROPERTY_ID as string;
export const TAWKTO_WIDGET_ID = REACT_APP_TAWKTO_WIDGET_ID as string;
export const CONSOLE_URL = REACT_APP_CONSOLE_URL as string;
export const IS_DEV = process.env.NODE_ENV === 'development';

export const TEAM_MEMBERS = [
  {
    name: 'Christopher Akanmu',
    role: 'CEO & Founder',
    image: '/chris.webp',
    linkedin: 'https://www.linkedin.com/in/christopher-akanmu-1b9438153/',
  },
  {
    name: 'Francesca Akanmu',
    role: 'CFO',
    image: '/francesca.webp',
    linkedin: 'https://www.linkedin.com/in/francesca-akanmu-8ba77013a',
  },
];

export const FIAT_CURRENCIES = [
  { name: 'Nigerian Naira', code: 'NGN', symbol: '₦' },
  { name: 'South African Rand', code: 'ZAR', symbol: 'R' },
  { name: 'United States Dollar', code: 'USD', symbol: '$' },
  { name: 'Euro', code: 'EUR', symbol: '€' },
  { name: 'British Pound', code: 'GBP', symbol: '£' },
  { name: 'Japanese Yen', code: 'JPY', symbol: '¥' },
  { name: 'Swiss Franc', code: 'CHF', symbol: 'CHF' },
  { name: 'Canadian Dollar', code: 'CAD', symbol: '$' },
  { name: 'Australian Dollar', code: 'AUD', symbol: '$' },
  { name: 'New Zealand Dollar', code: 'NZD', symbol: '$' },
  { name: 'Chinese Yuan', code: 'CNY', symbol: '¥' },
  { name: 'Swedish Krona', code: 'SEK', symbol: 'kr' },
  { name: 'Norwegian Krone', code: 'NOK', symbol: 'kr' },
  { name: 'Danish Krone', code: 'DKK', symbol: 'kr' },
  { name: 'Indian Rupee', code: 'INR', symbol: '₹' },
  { name: 'Russian Ruble', code: 'RUB', symbol: '₽' },
  { name: 'Brazilian Real', code: 'BRL', symbol: 'R$' },
  { name: 'Mexican Peso', code: 'MXN', symbol: '$' },
  { name: 'Singapore Dollar', code: 'SGD', symbol: '$' },
  { name: 'Hong Kong Dollar', code: 'HKD', symbol: '$' },
  { name: 'South Korean Won', code: 'KRW', symbol: '₩' },
  { name: 'Turkish Lira', code: 'TRY', symbol: '₺' },
  { name: 'Indonesian Rupiah', code: 'IDR', symbol: 'Rp' },
  { name: 'Malaysian Ringgit', code: 'MYR', symbol: 'RM' },
  { name: 'Thai Baht', code: 'THB', symbol: '฿' },
  { name: 'United Arab Emirates Dirham', code: 'AED', symbol: 'د.إ' },
  { name: 'Polish Zloty', code: 'PLN', symbol: 'zł' },
  { name: 'Hungarian Forint', code: 'HUF', symbol: 'Ft' },
  { name: 'Israeli New Shekel', code: 'ILS', symbol: '₪' },
  { name: 'Czech Koruna', code: 'CZK', symbol: 'Kč' },
  { name: 'Philippine Peso', code: 'PHP', symbol: '₱' },
  { name: 'Chilean Peso', code: 'CLP', symbol: '$' },
  { name: 'Argentine Peso', code: 'ARS', symbol: '$' },
  { name: 'Colombian Peso', code: 'COP', symbol: '$' },
  { name: 'Peruvian Sol', code: 'PEN', symbol: 'S/.' },
  { name: 'Romanian Leu', code: 'RON', symbol: 'lei' },
  { name: 'Angolan Kwanza', code: 'AOA', symbol: 'Kz' },
  { name: 'Ugandan Shilling', code: 'UGX', symbol: 'USh' },
  { name: 'Zambian Kwacha', code: 'ZMW', symbol: 'ZK' },
  { name: 'Eritrean Nakfa', code: 'ERN', symbol: 'Nfk' },
  { name: 'São Tomé and Príncipe Dobra', code: 'STN', symbol: 'Db' },
];
