import { CONSOLE_URL } from '@variable';

export const FAQ_ITEMS = [
  {
    question: 'How can I integrate with the product?',
    answer: `To integrate with our product, start by <a href="${CONSOLE_URL}" target="_blank">creating an account.</a> Then, follow our developer guide for integration, complete the KYC process, and you’ll be ready to receive payments.`,
  },
  {
    question: 'Can I receive payments without completing KYC?',
    answer:
      'No, completing the KYC process is mandatory before you can receive payments. This ensures compliance with international regulations and protects your business from fraud.',
  },
  {
    question: 'What documents are required for KYC?',
    answer:
      'You will need to provide company documents, government-issued ID cards, and other relevant information to complete the KYC process.',
  },
  {
    question: 'How can I use ZinariPay with my WordPress/Shopify site?',
    answer:
      'The CDN is the easiest way to integrate ZinariPay into your existing business since we do not offer dedicated plugins for these platforms at this time.',
  },
  {
    question: 'Which cryptocurrencies does your gateway support?',
    answer:
      'Our gateway supports only USDT and USDC for now, but this list is growing daily. This allows you to receive payments quickly and securely from anywhere in the world.',
  },
  {
    question: 'Is my data secure with your payment gateway?',
    answer:
      'Yes, security is our top priority. We use advanced encryption protocols and comply with global security standards like PCI DSS to ensure the safety of your transactions and data.',
  },
  {
    question: 'What fees are associated with using your gateway?',
    answer:
      'Fees vary by country. For specific countries, fees are listed on our pricing page. For all other countries, a flat 3% fee applies.',
  },
  {
    question: 'Can I track my transactions on the blockchain?',
    answer:
      'Yes, every transaction processed through our gateway comes with a blockchain transaction ID, allowing you to track all your incoming and outgoing payments directly on the blockchain.',
  },
  {
    question: 'How quickly can I receive payments?',
    answer:
      'With our cryptocurrency payout options, payments are processed almost instantly, allowing you to access your funds quickly without the delays associated with traditional banking systems.',
  },
  {
    question: 'Is there a limit to the number of transactions I can process?',
    answer:
      'Our gateway is designed to scale with your business, allowing you to process an unlimited number of transactions as your business grows.',
  },
  {
    question: 'What kind of support do you offer if I run into issues?',
    answer:
      'We provide 24/7 customer support via email, chat. Additionally, larger clients have access to dedicated account managers for personalized assistance.',
  },
  {
    question: 'Can I customize the payment gateway to match my brand?',
    answer:
      'Yes, our platform offers extensive customization options, allowing you to tailor the user interface, payment options, and integration settings to fit your brand’s identity and business needs.',
  },
];
