import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';

const Content: FC = () => (
  <>
    <Typography variant="h1" component="h1" gutterBottom>
      Anti-Bribery, Anti-Corruption, and Anti-Money Laundering Policy
    </Typography>
    <Stack spacing={3}>
      <Typography variant="h2">1. Introduction</Typography>
      <Typography variant="body1">
        Zinari Technologies Nig Ltd (hereinafter referred to as “Zinari Corp”)
        is committed to complying with all applicable laws in their operations
        including but not limited to the Corrupt Practices and Other Related
        Offences Act 2000, the Money Laundering (Prohibition) Act 2011 (as
        amended), and the Advance Fee Fraud and Other Fraud Related Offences Act
        2006.
      </Typography>

      <Typography variant="h2">2. Scope</Typography>
      <Typography variant="body1">
        The scope of this Policy applies to every senior manager, officer,
        employee (whether permanent, fixed-term or temporary), consultant,
        contractor, trainee, volunteer, intern, agent, sponsor, and members of
        the board of Zinari Corp, and reflect the standard to which the company
        expects its business associates and partners to adhere to when acting on
        the company’s behalf.
        <br />
        This Policy is to supplement all applicable laws, rules, and other
        corporate policies.
      </Typography>

      <Typography variant="h2">3. Policy Statement</Typography>
      <Typography variant="body1">
        Bribery is the offering, promising, giving, accepting or soliciting of
        an advantage as an inducement for action that is illegal. A bribe is an
        inducement or reward offered, promised, or provided to gain any
        commercial, contractual, regulatory, or personal advantage. It is our
        policy to conduct all of our business in an honest and ethical manner.
        <br />
        We have a zero-tolerance approach to bribery and corruption. We are
        committed to acting professionally, fairly, and with integrity in all
        our business dealings and relationships wherever and whenever we
        operate, and so the company prohibits the paying, promising,
        authorizing, or offering to give anything of value to any government
        official, private individual, political party, or political candidate to
        cause that official to do or not to do any act in violation of his
        official function or to secure any improper advantage, whether in cash
        or in any other form.
      </Typography>

      <Typography variant="h2">4. How to Raise a Concern</Typography>
      <Typography variant="body1">
        Staff are encouraged to raise concerns about any issue or suspicion of
        malpractice as soon as possible. If an employee becomes aware or
        suspects that an activity or conduct which is proposed or has taken
        place is a bribe or corrupt, then they have a duty to report it.
      </Typography>

      <Typography variant="h2">5. Record Keeping</Typography>
      <Typography variant="body1">
        The company will keep financial records and have appropriate internal
        controls in place to evidence the business reason for making payments to
        third parties. Staff must declare and keep a written record of all
        hospitality or gifts accepted or offered, which will be subject to
        managerial review.
      </Typography>

      <Typography variant="h2">6. Fraud</Typography>
      <Typography variant="body1">
        Staff must not engage in any act aimed at defrauding any person of
        money, property, or honest services. This also includes any dishonest
        misuse or theft of company assets, or falsification of expense reports.
      </Typography>

      <Typography variant="h2">7. Money Laundering</Typography>
      <Typography variant="body1">
        We are obliged to comply with the Money Laundering (Prohibition) Act
        2011 (as amended) and deter money laundering. We may also seek the
        written assurance of business associates and partners that they have
        records evidencing that their clients have been duly identified in
        accordance with the applicable anti-money laundering law or request
        copies of such records from them as applicable.
      </Typography>

      <Typography variant="h2">8. Conclusion</Typography>
      <Typography variant="body1">
        This policy shall be reviewed regularly and may be updated from time to
        time.
      </Typography>
    </Stack>
  </>
);

export default Content;
