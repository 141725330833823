import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';

import Helmet from '@component/Helmet';
import { FIAT_CURRENCIES } from '@variable';

const SupportedCurrencies = () => (
  <Container maxWidth="md">
    <Helmet
      title="Supported Fiat Currencies"
      description="View the list of fiat currencies supported by Zinari Corp for payments and transactions."
      keywords="fiat currencies, supported currencies, payments, Zinari Corp"
    />
    <Typography variant="h1" component="h1" gutterBottom>
      Supported Fiat Currencies
    </Typography>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Symbol</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {FIAT_CURRENCIES.map(({ code, name, symbol }) => (
            <TableRow key={code}>
              <TableCell>{name}</TableCell>
              <TableCell>{code}</TableCell>
              <TableCell>{symbol}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Container>
);

export default SupportedCurrencies;
