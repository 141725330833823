import { Outlet as RouterOutlet, useLocation } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Slide,
  SlideProps,
  Snackbar,
  SnackbarCloseReason,
  Stack,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';

import Header from '@component/Header';
import Footer from '@component/Footer';
import { CONSOLE_URL } from '@variable';

function SlideTransition({ children, ...props }: SlideProps) {
  return (
    <Slide {...props} direction="up">
      {children}
    </Slide>
  );
}

const Layout = () => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setOpen(true);
  //   }, 45000);
  // }, []);

  const handleClose = (
    event: SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box
      sx={{
        pt: { sm: 8, xs: 7 },
        backgroundImage: 'url("/background.webp")',
        backgroundSize: { md: 'contain', lg: 'cover' },
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Header />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pb: 3,
          pt: { sm: 6, xs: 8 },
          background: 'transparent',
        }}
      >
        <RouterOutlet />
      </Box>
      <Footer />
      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
      >
        <Alert severity="info" icon={<></>} onClose={handleClose}>
          <Stack spacing={1} alignItems="center">
            <AlertTitle
              variant="h6"
              textTransform="uppercase"
              gutterBottom={false}
              sx={{ fontWeight: 900 }}
            >
              {`🎁`} Limited Offer {`🎉`}
            </AlertTitle>
            <Typography variant="subtitle2" gutterBottom textAlign="center">
              No processing fees for the first 3 months <br /> when you
            </Typography>
            <Button
              color="info"
              size="small"
              variant="contained"
              href={CONSOLE_URL}
              target={'_blank'}
            >
              Sign Up Today!
            </Button>
          </Stack>
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Layout;
