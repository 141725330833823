import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Stack,
} from '@mui/material';
import React from 'react';

import { CONSOLE_URL } from '@variable';

import WhyChooseUsSection from './WhyChooseUsSection';
import AboutUsSnapshot from './AboutUsSnapshot';
import KeyFeaturesSection from './KeyFeaturesSection';
import HeroSection from './HeroSection';
import HowItWorksSection from './HowItWorksSection';
import CTABanner from './CTABanner';

const Offer = () => (
  <Box>
    <Alert
      severity="info"
      sx={{ textAlign: 'center', width: 'fit-content', margin: 'auto' }}
      icon={<></>}
    >
      <Stack spacing={2} alignItems="center">
        <AlertTitle>
          <b>
            Start Accepting Payments with No Fees for the First 3 Months!🤑 🎉
            🎁
          </b>
        </AlertTitle>

        <div>
          Sign up today and enjoy zero processing fees for your first three
          months—limited-time offer for new users!
        </div>

        <Button
          color="info"
          variant="contained"
          href={CONSOLE_URL}
          target={'_blank'}
          sx={{ width: 'fit-content' }}
        >
          Start now!!
        </Button>
      </Stack>
    </Alert>
  </Box>
);

const Landing = () => {
  return (
    <Container maxWidth={'md'}>
      <HeroSection />
      <KeyFeaturesSection />
      <WhyChooseUsSection />
      <Offer />
      <HowItWorksSection />
      <AboutUsSnapshot />
      <CTABanner />
    </Container>
  );
};

export default Landing;
