import React, { FC } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Grid2 as Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FAQ_ITEMS } from '@page/Contact/constants';
import useTawkTo from '@hook/useTawkTo.hook';
import Helmet from '@component/Helmet';

const ContactInfo: FC = () => {
  const { maximize } = useTawkTo();

  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <Typography variant="body1">
          Email Us: <a href="mailto:support@zinari.io">support@zinari.io</a>
        </Typography>
      </Grid>
      <Grid size={12}>
        <Typography variant="body1">
          Support Hours: 24 hours a day, Monday to Friday via online channels
        </Typography>
      </Grid>

      <Grid size={12}>
        <Button variant="contained" color="primary" onClick={maximize}>
          Chat with Support
        </Button>
      </Grid>

      <SocialMediaLinks />
    </Grid>
  );
};

const SocialMediaLinks: FC = () => (
  <Grid container spacing={1}>
    <Grid>
      <IconButton href="https://web.facebook.com/zinaripay" target="_blank">
        <FacebookIcon sx={{ color: '#1877F2' }} />
      </IconButton>
    </Grid>
    <Grid>
      <IconButton href="https://instagram.com/zinaripay" target="_blank">
        <InstagramIcon sx={{ color: '#E4405F' }} />
      </IconButton>
    </Grid>
    <Grid>
      <IconButton
        href="https://www.linkedin.com/company/zinaripay/"
        target="_blank"
      >
        <LinkedInIcon sx={{ color: '#0A66C2' }} />
      </IconButton>
    </Grid>
    <Grid>
      <IconButton href="https://x.com/zinaripay" target="_blank">
        <XIcon sx={{ color: '#000' }} />
      </IconButton>
    </Grid>
  </Grid>
);

const FAQSection: React.FC = () => (
  <Grid container spacing={2}>
    <Grid size={12}>
      <Typography variant="h5">Frequently Asked Questions</Typography>
    </Grid>
    <Grid size={12}>
      {FAQ_ITEMS.map(({ question, answer }, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div dangerouslySetInnerHTML={{ __html: answer }} />
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  </Grid>
);

const ContactPage: FC = () => (
  <Container maxWidth="md">
    <Helmet
      title="Contact Us"
      description="Get in touch with ZinariPay for any inquiries or support. We are here to assist you with seamless integration, secure transactions, and scalable payment solutions for your business."
      keywords="ZinariPay contact, customer support, cryptocurrency payment support, fintech inquiries, blockchain payment help"
    />

    {/* Title and Subtitle */}
    <Box mb={2}>
      <Typography variant="h4" component="h1" fontWeight={700}>
        Contact Us
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        We&apos;re here to help! Reach out to us through any of the channels
        below.
      </Typography>
    </Box>

    <Grid container spacing={4}>
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={4}>
          <ContactInfo />
        </Grid>
      </Paper>
      <FAQSection />
    </Grid>
  </Container>
);

export default ContactPage;
