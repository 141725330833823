import { Box, Divider, Paper, Stack, Typography } from '@mui/material';

import { FEATURES } from './constants';

const FeaturesSection = () => {
  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={4}>
        {FEATURES.map(({ title, description, details, icon: Icon }, index) => (
          <div key={index}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Box sx={{ mr: 2 }}>
                <Icon />
              </Box>
              <Typography variant="h4" component="h2">
                {title}
              </Typography>
            </Box>
            <Typography variant="body1" gutterBottom>
              {description}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <span dangerouslySetInnerHTML={{ __html: details }}></span>
            </Typography>
            {index !== FEATURES.length - 1 && <Divider sx={{ mt: 4 }} />}
          </div>
        ))}
      </Stack>
    </Paper>
  );
};

export default FeaturesSection;
