import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';

import { CONSOLE_URL } from '@variable';

import hero from './payment-flow.webp';

const HeroSection = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${hero})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        textAlign: 'center',
        color: 'white',
      }}
    >
      <Container maxWidth="md" sx={{ bgcolor: 'rgba(0,0,0,0.7)', py: 10 }}>
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          fontFamily="Open Sans"
          fontWeight="bold"
        >
          Unlocking the Power of Cryptocurrency Payments for Your Businesses
        </Typography>
        {/*<Typography*/}
        {/*  variant="h5"*/}
        {/*  component="p"*/}
        {/*  gutterBottom*/}
        {/*  fontFamily="Open Sans"*/}
        {/*>*/}
        {/*  Experience fast, secure, and globally accessible payments with our*/}
        {/*  innovative gateway.*/}
        {/*</Typography>*/}
        <Button
          variant="contained"
          color="secondary"
          size="large"
          sx={{ mt: 4 }}
          href={CONSOLE_URL}
          target={'_blank'}
        >
          Get Started
        </Button>
      </Container>
    </Box>
  );
};

export default HeroSection;
