import React from 'react';
import {
  Box,
  Button,
  Container,
  Grid2 as Grid,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { TEAM_MEMBERS } from '@variable';
import TeamMember from '@component/TeamMember';

const AboutUsSnapshot = () => {
  return (
    <Box sx={{ py: 10 }}>
      <Container maxWidth="md">
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ textAlign: 'center' }}
        >
          About Us
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ textAlign: 'center', mb: 4 }}
        >
          We are a passionate team dedicated to revolutionizing cryptocurrency
          payments. Our mission is to provide secure, scalable, and
          easy-to-integrate solutions that empower businesses worldwide.
        </Typography>
        <Grid container spacing={4}>
          {TEAM_MEMBERS.slice(0, 2).map((member, index) => (
            <Grid size={{ xs: 12, sm: 6 }} key={index}>
              <TeamMember {...member} />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/about"
          >
            Learn More About Us
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutUsSnapshot;
