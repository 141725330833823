import React, { FC, MouseEvent, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import { CONSOLE_URL } from '@variable';
import PromoStrip from '@component/Header/PromoStrip';

const MENU_ITEMS = [
  { label: 'Home', link: '/' },
  { label: 'Features', link: '/features' },
  { label: 'PayLink', link: '/paylink' },
  {
    label: 'Documentation',
    link: 'https://www.npmjs.com/package/zinari-pay',
    target: '_blank',
  },
  { label: 'Pricing & Fees', link: '/pricing' },
  { label: 'About Us', link: '/about' },
  { label: 'Contact', link: '/contact' },
];

const Header: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  return (
    <AppBar position="fixed" color="primary" elevation={2}>
      <PromoStrip />
      <Toolbar>
        {/* Logo Placeholder */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width={'100%'}
        >
          <button
            onClick={() => navigate('/')}
            style={{
              border: 0,
              background: 'transparent',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src="/logo.webp"
              alt="Logo"
              style={{ height: 'auto', width: 100 }}
            />
          </button>

          {/* Desktop Menu */}
          {!isMobile && (
            <Box overflow={'scroll'}>
              {MENU_ITEMS.map(({ label, link, target }) => (
                <Button
                  key={label}
                  component={RouterLink}
                  to={link}
                  color="inherit"
                  target={target}
                  sx={{
                    textDecoration:
                      window.location.pathname === link ? 'underline' : 'none',
                    textUnderlineOffset: 9,
                  }}
                >
                  {label}
                </Button>
              ))}

              <Button
                variant={'outlined'}
                color={'secondary'}
                href={CONSOLE_URL}
                target={'_blank'}
                sx={{ ml: 3 }}
              >
                Login
              </Button>
              <Button
                variant={'contained'}
                color={'secondary'}
                href={CONSOLE_URL}
                target={'_blank'}
                sx={{ ml: 3 }}
              >
                Register
              </Button>
            </Box>
          )}

          {/* Mobile Menu */}
          {isMobile && (
            <>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {MENU_ITEMS.map(({ label, link, target }) => (
                  <MenuItem
                    key={label}
                    component={RouterLink}
                    to={link}
                    onClick={handleMenuClose}
                    target={target}
                    sx={{
                      textDecoration:
                        window.location.pathname === link
                          ? 'underline'
                          : 'none',
                      textUnderlineOffset: 9,
                    }}
                  >
                    {label}
                  </MenuItem>
                ))}

                <MenuItem
                  component={RouterLink}
                  to={CONSOLE_URL}
                  onClick={handleMenuClose}
                  target={'_blank'}
                >
                  Login
                </MenuItem>
                <MenuItem
                  component={RouterLink}
                  to={CONSOLE_URL}
                  onClick={handleMenuClose}
                  target={'_blank'}
                >
                  Register
                </MenuItem>
              </Menu>
            </>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
