import React from 'react';
import { Container } from '@mui/material';

import Helmet from '@component/Helmet';
import Content from '@page/PayLink/Content';

const PayLink = () => (
  <Container maxWidth="md">
    <Helmet
      title="Zinari PayLink - Simple Crypto Payments for Non-developers"
      description="Zinari PayLink allows businesses to easily accept USDT and USDC payments without any coding or technical expertise. Sign up, complete KYC, and start sending payment links via email."
      keywords="ZinariPay, PayLink, crypto payments, USDT, USDC, no coding, non-developers, KYC, secure payments, cryptocurrency payment gateway"
    />
    <Content />
  </Container>
);

export default PayLink;
