import React from 'react';
import {
  Alert,
  AlertTitle,
  Container,
  Grid2 as Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import Helmet from '@component/Helmet';

const FEES_BY_COUNTRY: { [key: string]: number } = {
  NG: 0.01,
};

const PricingTable: React.FC = () => {
  const countries = Object.keys(FEES_BY_COUNTRY);

  return (
    <Container maxWidth={'md'}>
      <Helmet
        title="Pricing"
        description="Explore ZinariPay's competitive pricing for secure and scalable cryptocurrency payment solutions. Understand our fee structure and discover how our transparent pricing can benefit your business."
        keywords="ZinariPay pricing, cryptocurrency payment fees, blockchain transaction costs, fintech pricing, transparent pricing"
      />

      <Grid container spacing={2}>
        <Grid size={12}>
          <Typography variant="h4" component="h1" fontWeight={700}>
            Pricing & Fees
          </Typography>
          <Typography variant="subtitle1">
            Below is the list of applicable fees for all countries.
          </Typography>
        </Grid>

        <Grid size={12}>
          <Alert severity="warning">
            <AlertTitle>
              <b>Withdrawal Fees 🧾</b>
            </AlertTitle>
            For all withdrawals of USDT or USDC, a fixed fee of 3 USDT/USDC will
            be charged, regardless of the withdrawal amount. This fee is applied
            uniformly to ensure consistent and transparent pricing for all
            transactions.
          </Alert>
        </Grid>

        <Grid size={12}>
          <Alert severity="info">
            <AlertTitle>
              <b>Limited Time Offer 🤑 🎉 🍾 🎁</b>
            </AlertTitle>
            No processing fees for the first 3 months!
          </Alert>
        </Grid>

        <Grid size={12}>
          <TableContainer component={Paper}>
            <Table aria-label="fees and taxes by country">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Country</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>Processing Fee (%)</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {countries.map((country) => (
                  <TableRow key={country}>
                    <TableCell>Nigeria</TableCell>
                    <TableCell align="right">1%</TableCell>
                  </TableRow>
                ))}
                {/* Row for all other countries */}
                <TableRow>
                  <TableCell>All other countries</TableCell>
                  <TableCell align="right">3%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PricingTable;
