import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import Layout from '@component/Layout';
import Landing from '@page/Landing';
import Contact from '@page/Contact';
import Pricing from '@page/Pricing';
import Features from '@page/Features';
import AboutUs from '@page/AboutUs';
import ErrorBoundary from '@component/ErrorBoundary';
import PrivacyPolicy from '@page/PrivacyPolicy';
import AntiPolicy from '@page/AntiPolicy';
import SupportedCurrencies from '@page/SupportedCurrencies';
import PayLink from '@page/PayLink';

const router = createBrowserRouter([
  {
    path: '',
    element: (
      <ErrorBoundary>
        <Layout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: '',
        element: <Landing />,
      },
      {
        path: 'features',
        element: <Features />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
      {
        path: 'about',
        element: <AboutUs />,
      },
      {
        path: 'paylink',
        element: <PayLink />,
      },
      {
        path: 'pricing',
        element: <Pricing />,
      },
      {
        path: 'privacy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'supported-fiat-currencies',
        element: <SupportedCurrencies />,
      },
      {
        path: 'anti-bribery-anti-corruption-and-anti-money-laundering-policy',
        element: <AntiPolicy />,
      },
      {
        path: '*',
        element: <Landing />,
      },
    ],
  },
]);

export default router;
